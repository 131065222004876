
import { Component, Vue } from "vue-property-decorator";
import AccountingMainSectionShimmer from "@/components/AccountingMainSectionShimmer.vue";
import AccountingMonthlyCardsListShimmer from "@/components/AccountingMonthlyCardsListShimmer.vue";
@Component({
  name: "AccountingShimmer",
  components: {
    AccountingMainSectionShimmer,
    AccountingMonthlyCardsListShimmer,
  },
})
export default class AccountingShimmer extends Vue {}
